import React from "react";
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import plusLogo from "../../static/logo-plus-550x120.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faInfoCircle, faBoxTissue } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';


const ElectionContent = (props) => {

    return (
       <div className="pageWrapper page textPage">
            <SEO 
                title={'Val 2022'}
                description="Samlade valkompasser för valet 2022"
                pagePath="/election"
            />

            <h1>Val 2022</h1>
            <div className="dividerHr">
                <span className="dividerSpan"></span>
            </div>

            <br />

            <h6>Samlade valkompasser för valet 2022</h6>

            <br /><br />

            <div className="aboutPage election" style={{textAlign: 'left'}}>
                <Row>
                    <Col>
                        <h5>Valkompass</h5>
                    
                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Riksdag
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 14 frågor
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://valkompass.com/" target="_blank">Starta</a>
                    </Col>
                    <Col>
                        <h5>Valkompass - Utmanare</h5>

                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Utmanarpartier
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 18 frågor
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://valkompass.com/kompass/utmanar2022" target="_blank">Starta</a>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h5>SVT</h5>
                    
                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Riksdag, kommun och Landsting
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 35 frågor för riksdag - 25 frågor för kommun/landsting
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://valkompass.svt.se/2022" target="_blank">Starta</a>
                    </Col>
                    <Col>                        
                        <h5>Företagarna</h5>
                    
                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Riksdag
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 30 frågor
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://www.foretagarna.se/kampanjer/valet2022/valkompass" target="_blank">Starta</a>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h5>Fokus</h5>
                    
                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Riksdag
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 25 frågor
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://www.fokus.se/valkompassen" target="_blank">Starta</a>
                    </Col>
                    <Col>
                        <h5>Aftonbladet</h5>
                    
                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Riksdag
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 30 frågor
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://www.aftonbladet.se/valkompassen" target="_blank">Starta</a>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h5>DN</h5>
                    
                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Riksdag
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 25 frågor
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://www.dn.se/valkompass" target="_blank">Starta</a>
                    </Col>
                    <Col>
                    <h5>TV4</h5>
                    
                        <ul className="electionList">
                            <li>
                                <FontAwesomeIcon icon={faBoxTissue} /> Riksdag
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faQuestionCircle} /> 25 frågor
                            </li>
                        </ul>

                        <a className="purpleBtn election" href="https://valkompassen.tv4.se" target="_blank">Starta</a>
                    </Col>
                </Row>

                <br /><br />

            </div>


        </div>
    )
}

export default injectIntl(ElectionContent);
