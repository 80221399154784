import React from "react";
import { graphql } from "gatsby";
import ElectionContent from "../components/electionContent";
import { useSelector } from 'react-redux';
import i18nMessages from '../i18n/sv.json';
import 'intl/locale-data/jsonp/sv';
import PageWrapper from "../components/pageWrapper";

const Election = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);

    return (
       <PageWrapper location={props.location} lang="sv" i18nMessages={i18nMessages}>
            <ElectionContent location={props.location} langUrl="/sv" />
        </PageWrapper>
    )
}

export default Election

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`